<template>
  <div>
    <div class="search-box">
      <h2 class="search-title border-bottom">按条件搜索</h2>
      <Form class="search-form"
            :label-width="85"
           >
        <Form-item label="选择对象">
          <Row v-if="!branchShow">
            <Col span="5">
            <span class="sub-label">省份：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="searchData.pid"
                      placeholder="请选择..."
                      @on-change="changePro">
                <Option :value="v.id"
                        v-for="v in proList"
                        :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">城市：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="searchData.cid"
                      placeholder="请选择..."
                      @on-change="changeCity">
                <Option :value="v.id"
                        v-for="v in cityList"
                        :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">门店：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="searchData.sid"
                      placeholder="请选择..."
                      @on-change="changeStore">
                <Option :value="v.id"
                        v-for="v in storeList"
                        :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">会员：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="uid"
                      placeholder="请选择..."
                      filterable>
                <Option :value="v.id"
                        v-for="v in userList"
                        :key="v.id">{{v.true_name}}</Option>
              </Select>
            </div>
            </Col>
          </Row>
        <Row v-else>
            <Col span="5">
              <span class="sub-label">省份：</span>
                <div class="sub-content">
                  <Input disabled v-model="proName"></Input>
                </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">城市：</span>
            <div class="sub-content">
              <Input disabled v-model="cityName"></Input>
            </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">门店：</span>
            <div class="sub-content">
              <Input disabled v-model="sName"></Input>
            </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">会员：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="uid"
                      placeholder="请选择..."
                      filterable>
                <Option :value="v.id"
                        v-for="v in userList"
                        :key="v.id">{{v.true_name}}</Option>
              </Select>
            </div>
            </Col>
          </Row>
        </Form-item>
        <Form-item label="对比项目">
          <Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll">全选</Checkbox>
        </Form-item>
        <Form-item label="">
          <CheckboxGroup v-model="comparisonProject" @on-change="checkAllGroupChange">
            <Checkbox :label="v" v-for="(v, i) in itemArr" :key="i"></Checkbox>
          </CheckboxGroup>
        </Form-item>
        <Form-item label="报告时间">
          <ul class="month-picker">
            <li :class="['month-picker-item', reportTime === item ? 'active': '']"
                v-for="(item, index) in recent5Months"
                :key="index"
                @click="handleClickReportTime(item)"
            >{{item}}</li>
          </ul>
          <DatePicker @on-change="hanldeReportTimeChange" type="month" placeholder="选择其他月份" style="width: 140px"></DatePicker>
        </Form-item>
        <Form-item label="">
          <Button @click="generateReport" style="margin-right: 4px;">生成报告</Button>
          <Button @click="saveAsPDF">存为PDF</Button>
          <Button @click="saveAsPng" style="margin-left: 4px;">存为PNG</Button>
        </Form-item>
      </Form>
    </div>
    <div class="content-box">
      <h2 class="content-title border-bottom">数据结果</h2>
      <div class="breadcrumbs-bar">
        <span :class="['breadcrumb', {'active': i === clickIndex}]" v-for="(v, i) in cacheProject" :key="i" @click="clickBreadcrumb(i)">{{v}}</span>
      </div>
      <div class="graph-content"
          ref="pdfContent">
        <div style="padding:20px 0;text-align:center;" v-if="cacheProject.length === 0">暂无数据</div>
        <life-behavior class="mb50" v-if="cacheProject.includes('适生行为报表')" :userInfo="userInfo" :data="lifeBehavior">
          <div style="font-size:22px">报告日期：{{putOn}}</div>
          <div><img class="header-img" src="@/assets/img/logo-new.png"></div>
        </life-behavior>
        <life-behavior-second class="mb50" v-if="cacheProject.includes('近体与复健')" :userInfo="userInfo" :data="lifeBehavior">
          <div style="font-size:22px">报告日期：{{putOn}}</div>
          <div><img class="header-img" src="@/assets/img/logo-new.png"></div>
        </life-behavior-second>
        <life-service class="mb50" v-if="cacheProject.includes('健康服务报表')" :userInfo="userInfo" :data="lifeService">
          <div style="font-size:22px">报告日期：{{putOn}}</div>
          <div><img class="header-img" src="@/assets/img/logo-new.png"></div>
        </life-service>
        <life-service-second class="mb50" v-if="cacheProject.includes('领货与退货')" :userInfo="userInfo" :data="lifeService">
          <div style="font-size:22px">报告日期：{{putOn}}</div>
          <div><img class="header-img" src="@/assets/img/logo-new.png"></div>
        </life-service-second>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import lifeBehavior from './life-behavior';
import lifeBehaviorSecond from './life-behavior-second';
import lifeService from './life-service';
import lifeServiceSecond from './life-service-second';
import qualityService from '@/services/qualityService';
import comparisonService from '@/services/comparisonService';
import dayjs from 'dayjs';

export default {
	components: {
		lifeBehavior,
		lifeBehaviorSecond,
		lifeService,
		lifeServiceSecond,
	},
	data() {
		return {
			options: {
				disabledDate(date) {
					return date && date.valueOf() > Date.now();
				},
			},
			searchData: {
				page: 1,
				size: 1000,
				pid: '',
				cid: '',
				sid: '',
				simple_query: 1,
			},
			branchShow: false,
			branch_id: [],
			power: [],
			proName: '',
			cityName: '',
			sName: '',
			storeList: [],
			proList: [],
			cityList: [],
			userList: [],
			uid: '',
			itemArr: ['适生行为报表', '近体与复健', '健康服务报表', '领货与退货'],
			putOn: '',
			comparisonProject: [],
			cacheProject: [],
			comparisonTime: [],
			customDatePicker: [{ time: '' }, { time: '' }, { time: '' }],
			referenceTime: false,
			compareTime: false,
			commentTime: false,
			report: {},
			overview: {},
			clickIndex: 0,
			scrollFunc: null,
			scrollPage: null,
			indeterminate: false,
			checkAll: false,
			userInfo: {},
			lifeBehavior: {},
			lifeService: {},
			lifeTrain: {},
			reportTime: '',
		};
	},
	computed: {
		recent5Months() {
			const result = [];
			for (let i = 0; i < 5; i++) {
				result.push(
					dayjs()
						.subtract(i * 1, 'month')
						.format('YYYY-MM'),
				);
			}
			return result;
		},
	},
	created() {
		let list = JSON.parse(localStorage.getItem('userInfo'));
		qualityService.getQualitySelect().then((data) => {
			this.power = list.user_info.power;
			if (this.power.indexOf('会员管理--查看所属店面会员信息权限') === -1) {
				this.branchShow = false;
			} else {
				if (
					this.power.indexOf('会员管理--查看全国会员信息权限') === -1 &&
					this.power.indexOf('会员管理--查看所属区域会员信息权限') === -1
				) {
					this.branchShow = true;
					this.branch_id = list.user_info.branch;
					this.searchData.sid = this.branch_id[2];
					this.searchData.cid = this.branch_id[1];
					this.searchData.pid = this.branch_id[0];
					this.proName = list.user_info.pro_name;
					this.cityName = list.user_info.city_name;
					this.sName = list.user_info.branch_name;
					this.changeStore();
				}
			}
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
	},
	mounted() {
		this.scrollPage = function (offsetArr, self) {
			const scrollTop = document.documentElement.scrollTop;
			switch (true) {
				case scrollTop < offsetArr[0]:
					self.clickIndex = 0;
					break;
				case scrollTop < offsetArr[1]:
					self.clickIndex = 1;
					break;
				case scrollTop < offsetArr[2]:
					self.clickIndex = 2;
					break;
				case scrollTop < offsetArr[3]:
					self.clickIndex = 3;
					break;
				case scrollTop < offsetArr[4]:
					self.clickIndex = 4;
					break;
				case scrollTop < offsetArr[5]:
					self.clickIndex = 5;
					break;
				case scrollTop < offsetArr[6]:
					self.clickIndex = 6;
					break;
				case scrollTop < offsetArr[7]:
					self.clickIndex = 7;
					break;
				default:
					self.clickIndex = offsetArr.length;
			}
		};
	},
	destroyed() {
		window.removeEventListener('scroll', this.scrollFunc);
	},
	methods: {
		// 根据省份选择城市
		changePro() {
			this.cityList = this.city[this.searchData.pid];
			this.searchData.cid = '';
		},
		// 根据城市选择门店
		changeCity() {
			this.storeList = this.store[this.searchData.cid];
			this.searchData.sid = '';
		},
		// 根据门店选择用户
		changeStore() {
			if (this.searchData.sid) {
				comparisonService.getUserList(this.searchData).then((data) => {
					this.userList = data.list;
				});
			} else {
				this.userList = [];
			}
		},
		// 点击 breadcrumb
		clickBreadcrumb(index) {
			this.clickIndex = index;
			const doc = document.documentElement;
			let scrollTop = doc.scrollTop;
			const anchor = document.querySelectorAll('.mb50')[index].offsetTop - 100;
			const scroll = function () {
				scrollTop = scrollTop + (anchor - scrollTop) / 4;
				if (Math.abs(scrollTop - anchor) < 1) {
					doc.scrollTop = anchor;
					return;
				}
				doc.scrollTop = scrollTop;
				requestAnimationFrame(scroll);
			};
			scroll();
		},
		// 生成报告
		generateReport() {
			if (!this.uid) {
				this.$Message.warning('请选择会员');
				return;
			}
			if (this.comparisonProject.length === 0) {
				this.$Message.warning('请选择项目');
				return;
			}
			if (!this.reportTime) {
				this.$Message.warning('请选择报告时间');
				return;
			}

			// 销毁滚动事件
			window.removeEventListener('scroll', this.scrollFunc);
			this.cacheProject = [];
			const params = {
				member_id: this.uid,
				compare: JSON.stringify(this.comparisonTime),
				custom: JSON.stringify(this.customTime),
				ny: this.reportTime,
			};
			comparisonService.getResults(params).then((data) => {
				this.itemArr.forEach((item) => {
					if (this.comparisonProject.includes(item)) {
						this.cacheProject.push(item);
					}
				});

				this.putOn = dayjs().format('YYYY/MM/DD');
				this.userInfo = {
					createTime: dayjs(data.create_time).format('YYYY年M月'),
					...data.overview,
				};
				this.lifeBehavior = {
					behavior: data.xwsj,
					item: data.xlxw,
					recovery: data.fjsj,
					threeCharts: data.xwbj,
				};
				this.lifeService = data.fwsj;
				this.lifeTrain = data.xlsj;

				// 延时加载滚动函数，此处 nextTick() 效果不完美
				setTimeout(() => {
					const self = this;
					const contentWrapper = document.querySelectorAll('.mb50');
					const offsetArr = Array.from(contentWrapper).map((item) => item.offsetTop - 120);
					offsetArr.shift();
					if (offsetArr.length > 0) {
						this.scrollFunc = function () {
							self.scrollPage(offsetArr, self);
						};
						window.addEventListener('scroll', this.scrollFunc);
					}
				}, 1000);
			});
		},
		saveAsPDF() {
			const printTarget = document.querySelectorAll('.overview-container');
			if (printTarget.length > 0) {
				const self = this;
				const pdf = new JsPDF({ compress: true, unit: 'pt' });
				Promise.all(
					Array.from(printTarget).map((item) =>
						html2canvas(item, {
							scale: 1,
							scrollX: 0,
							scrollY: -window.scrollY,
						}),
					),
				).then((canvasList) => {
					for (let i = 0; i < printTarget.length; i++) {
						const canvas = canvasList[i];
						const contentWidth = canvas.width;
						const contentHeight = canvas.height;

						const imgWidth = 555.28;
						const imgHeight = (imgWidth / contentWidth) * contentHeight;

						const pageData = canvas.toDataURL('image/png', 1.0);

						if (i !== 0) pdf.addPage();
						pdf.addImage(pageData, 'png', 20, 0, imgWidth, imgHeight);

						if (i === printTarget.length - 1) {
							pdf.save(`${self.userInfo.name}${self.userInfo.createTime}月度报表.pdf`);
						}
					}
				});
			} else {
				this.$Message.warning('无法生成PDF，请检查对比报告是否存在');
			}
		},
		saveAsPng() {
			const printTarget = document.querySelectorAll('.overview-container');
			if (printTarget.length > 0) {
				const self = this;
				html2canvas(this.$refs.pdfContent).then(function (canvas) {
					const imgData = canvas.toDataURL('image/png', 1.0);
					const a = document.createElement('a');
					a.href = imgData;
					a.style.display = 'none';
					a.setAttribute(
						'download',
						`${self.userInfo.name}${self.userInfo.createTime}月度报表.png`,
					);
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
				});
			} else {
				this.$Message.warning('无法生成PNG，请检查对比报告是否存在');
			}
		},
		handleCheckAll() {
			if (this.indeterminate) {
				this.checkAll = false;
			} else {
				this.checkAll = !this.checkAll;
			}
			this.indeterminate = false;

			if (this.checkAll) {
				this.comparisonProject = this.itemArr;
			} else {
				this.comparisonProject = [];
			}
		},
		checkAllGroupChange(data) {
			if (data.length === this.itemArr.length) {
				this.indeterminate = false;
				this.checkAll = true;
			} else if (data.length > 0) {
				this.indeterminate = true;
				this.checkAll = false;
			} else {
				this.indeterminate = false;
				this.checkAll = false;
			}
		},
		hanldeReportTimeChange(val) {
			if (val) this.reportTime = val;
		},
		handleClickReportTime(val) {
			this.reportTime = val;
		},
	},
};
</script>

<style lang="less" scoped>
.sub-label {
  vertical-align: middle;
  float: left;
}

.sub-content {
  margin-left: 50px;
}

.btn-add {
  cursor: pointer;
  margin-left: 10px;
}

.btn-add:hover {
  color: #fdad00;
}

.content-box {
  background-color: #fff;
  padding-bottom: 20px;
}

.breadcrumbs-bar {
  display: flex;
  border-bottom: #eee solid 1px;
  position: sticky;
  top: 23px;
  background-color: #fff;
  z-index: 10;
  .breadcrumb {
    flex: 1;
    text-align: center;
    padding: 10px 0;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      color: #fdad00;
    }
    &.active {
      color: #fdad00;
    }
  }
}

.content-title {
  height: 39px;
  line-height: 39px;
  padding-left: 10px;
  background-color: #fbfbfb;
}

.graph-content {
  padding: 0 30px;
}

.mb50 {
  margin-bottom: 50px;
}

.content-panel {
  display: flex;
  justify-content: center;
  .sub-tab {
    padding: 22px 15px;
    &-item {
      height: 32px;
      line-height: 32px;
      width: 140px;
      text-align: center;
      background-color: #fff;
      margin-bottom: 20px;
      border-radius: 4px;
      box-shadow: 0 2px 5px #bbb;
      cursor: pointer;
      &.active {
        background-color: #fdad00;
        color: #fff;
        font-weight: 700;
      }
    }
  }
}

.month-picker {
  display: inline-block;
  vertical-align: middle;
  &-item {
    border-radius: 4px;
    border: #fdad00 solid 1px;
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
    display: inline-block;
    margin-right: 4px;
    &.active {
      background-color: #fdad00;
      color: #fff;
    }
  }
}

.header-img {
  width: 220px;
  margin-right: 5px;
}
</style>
