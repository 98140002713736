<template>
	<div class="overview-container">
		<div class="graph-header">
			<slot></slot>
		</div>
		<div class="graph-headings">
			<h2 class="graph-h2">{{userInfo.createTime}}健康服务报表</h2>
			<h3 class="graph-h3">Stage physiological behavior report</h3>
		</div>
		<div class="user-info-panel">
			<div class="user-info">
				<span class="user-info-label">姓名</span>
				<span class="user-info-text">{{userInfo.name}}</span>
			</div>
			<div class="user-info">
				<span class="user-info-label">性别</span>
				<span class="user-info-text">{{userInfo.sex}}</span>
			</div>
			<div class="user-info">
				<span class="user-info-label">年龄</span>
				<span class="user-info-text">{{userInfo.age}}</span>
			</div>
			<div class="user-info">
				<span class="user-info-label">门店</span>
				<span class="user-info-text">{{userInfo.branch}}</span>
			</div>
		</div>
		<div class="table-panel">
			<p class="table-title" style="background-color: #fdad4a;">领货</p>
			<table class="second-table">
				<tr>
					<th>领货名称</th>
					<th>领货数量</th>
				</tr>
				<tr v-for="(item, i) in data.lhsj" :key="i">
					<td>{{item.drug_class__name}}</td>
					<td>{{item.cs}}</td>
				</tr>
			</table>
		</div>
	</div>
</template>

<script  type="text/ecmascript-6">
export default {
	props: {
		userInfo: {
			type: Object,
		},
		data: {
			type: Object,
		},
	},
};
</script>

<style lang="less" scoped>
.user-info-panel {
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
}
.user-info-text {
  padding: 5px 25px;
  border-bottom: #000 solid 1px;
  margin-left: 5px;
}
.main-table {
	width: 80%;
	text-align: center;
	table-layout: fixed;
	margin: 30px auto 0;
	font-size: 18px;
	font-weight: 700;
	th {
		padding: 5px;
		border: 1px #bbb solid;
		:nth-child(odd) {
			background-color: #efefef;
		}
	}
	td {
		padding: 5px;
		border: 1px #bbb solid;
	}
}
.table-panel {
	margin-top: 30px;
	.table-title {
		padding: 6px;
		font-weight: 700;
		font-size: 16px;
		color: #fff;
		text-align: center;
	}
	.second-table {
		width: 100%;
		text-align: center;
		table-layout: fixed;
		td, th {
			padding: 5px;
			border: #bbb 1px solid;
			font-size: 16px;
		}
	}
}
.flex-table {
	display: flex;
}
</style>
