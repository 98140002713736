<template>
		<div class="overview-container">
			<div class="graph-header">
				<slot></slot>
			</div>
			<div class="graph-headings">
				<h2 class="graph-h2">{{userInfo.createTime}}适生行为报表</h2>
				<h3 class="graph-h3">Stage physiological behavior report</h3>
			</div>
			<div class="user-info-panel">
				<div class="user-info">
					<span class="user-info-label">姓名</span>
					<span class="user-info-text">{{userInfo.name}}</span>
				</div>
				<div class="user-info">
					<span class="user-info-label">性别</span>
					<span class="user-info-text">{{userInfo.sex}}</span>
				</div>
				<div class="user-info">
					<span class="user-info-label">年龄</span>
					<span class="user-info-text">{{userInfo.age}}</span>
				</div>
				<div class="user-info">
					<span class="user-info-label">门店</span>
					<span class="user-info-text">{{userInfo.branch}}</span>
				</div>
			</div>
			<table class="main-table">
				<tr>
					<th>行为</th>
					<th>行为目标</th>
					<th>单位</th>
					<th>期间平均值</th>
					<th>与标准差距</th>
					<th>期间积累</th>
					<th>执行天数</th>
					<th>平均执行率</th>
				</tr>
				<tr v-for="(item, i) in data.behavior" :key="i">
					<td>{{item.xw}}</td>
					<td>{{item.xwmb}}</td>
					<td>{{item.dw}}</td>
					<td>{{item.qjpjz}}</td>
					<td>{{item.ybzcj}}</td>
					<td>{{item.qjlj}}</td>
					<td>{{item.zxts}}</td>
					<td>{{item.pjzxl}}</td>
				</tr>
			</table>
			<table class="main-table">
				<tr>
					<th>训练</th>
					<th>目标</th>
					<th>单位</th>
					<th>实际值</th>
					<th>平均执行率</th>
				</tr>
				<tr>
					<td>训练行为</td>
					<td>{{data.item.xlxw_mb}}</td>
					<td>次/月</td>
					<td>{{data.item.xlxw_sjz}}</td>
					<td>{{data.item.xlxw_zxl}}</td>
				</tr>
				<tr>
					<td>平均训练时间</td>
					<td>{{data.item.pjxlsj_mb}}</td>
					<td>min/次</td>
					<td>{{data.item.pjxlsj_sjz}}</td>
					<td>{{data.item.pjxlsj_zxl}}</td>
				</tr>
			</table>
			<div class="graph-container" ref="graph"></div>
		</div>
</template>

<script  type="text/ecmascript-6">
import echarts from 'echarts';

export default {
	props: {
		userInfo: {
			type: Object,
		},
		data: {
			type: Object,
		},
	},
	data() {
		return {};
	},
	mounted() {
		echarts.init(this.$refs.graph).setOption({
			title: {
				left: 'center',
				text: '月度训练曲线',
			},
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'cross',
					crossStyle: {
						color: '#999',
					},
				},
			},
			legend: {
				data: ['训练时间', '平均心率', '最高心率'],
				bottom: 0,
			},
			xAxis: [
				{
					type: 'category',
					data: this.data.item.ydxlqx.cjsj,
					axisPointer: {
						type: 'shadow',
					},
				},
			],
			yAxis: [
				{
					type: 'value',
					name: '训练时间',
				},
				{
					type: 'value',
					name: '心率',
				},
			],
			series: [
				{
					name: '训练时间',
					type: 'bar',
					data: this.data.item.ydxlqx.xlsj,
					color: '#9bbb59',
				},
				{
					name: '平均心率',
					type: 'line',
					yAxisIndex: 1,
					data: this.data.item.ydxlqx.pjxl,
					color: '#4f81bd',
				},
				{
					name: '最高心率',
					type: 'line',
					yAxisIndex: 1,
					data: this.data.item.ydxlqx.zgxl,
					color: '#c0504d',
				},
			],
		});
	},
	methods: {
		buildChart(ele, title, yData, target) {
			const series = [
				{
					type: 'bar',
					data: yData,
					color: '#757575',
					markLine: {
						symbol: 'none',
						data: [
							{
								type: 'average',
								name: '期间平均值',
								label: {
									normal: {
										position: 'insideEndTop',
										formatter: '{b}',
									},
								},
								lineStyle: {
									color: '#000',
									type: 'solid',
								},
							},
						],
					},
				},
			];
			if (target) {
				const data = new Array(3).fill(target);
				series.push({
					type: 'line',
					symbol: 'none',
					data,
					lineStyle: {
						width: 0,
					},
					markLine: {
						symbol: 'none',
						data: [
							{
								type: 'average',
								name: '行为目标',
								label: {
									normal: {
										position: 'insideEndTop',
										formatter: '{b}',
									},
								},
								lineStyle: {
									color: '#000',
									type: 'solid',
								},
							},
						],
					},
				});
			}
			echarts.init(ele).setOption({
				title: {
					left: 'center',
					text: title,
				},
				grid: {
					left: '38',
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						crossStyle: {
							color: '#999',
						},
					},
				},
				xAxis: [
					{
						type: 'category',
						data: this.data.threeCharts.x_list,
						axisPointer: {
							type: 'shadow',
						},
					},
				],
				yAxis: [{ type: 'value' }, { type: 'value' }],
				series,
			});
		},
	},
};
</script>

<style lang="less" scoped>
.user-info-panel {
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
}
.user-info-text {
  padding: 5px 25px;
  border-bottom: #000 solid 1px;
  margin-left: 5px;
}
.main-table {
	width: 100%;
	margin-top: 30px;
	text-align: center;
	table-layout: fixed;
	tr:nth-child(odd) {
		background-color: #efefef;
	}
	th {
		background-color: #4472c4;
		font-weight: 700;
		color: #fff;
		padding: 8px 4px;
		border: 1px #000 solid;
	}
	td {
		padding: 10px 5px;
		border: 1px #000 solid;
	}
}
.graph-container {
	margin-top: 30px;
	width: 100%;
	height: 400px;
	padding-top: 30px;
	border-top: #bbb solid 1px;
}
.graph-flex-box {
	display: flex;
	justify-content: space-between;
	height: auto;
	.graph-flex-panel {
		width: 32.5%;
	}
}
.table-panel {
	margin-top: 30px;
	.table-title {
		padding: 20px;
		font-weight: 700;
		font-size: 16px;
		color: #fff;
		text-align: center;
	}
	.second-table {
		width: 100%;
		text-align: center;
		table-layout: fixed;
		td {
			height: 80px;
			border: #eee 2px solid;
			font-size: 16px;
		}
	}
}
.graph-panel {
	height: 400px;
}
.graph-title,
.graph-content {
	margin-bottom: 5px;
	font-weight: 700;
}
</style>
