<template>
	<div class="overview-container">
			<div class="graph-header">
				<slot></slot>
			</div>
			<div class="graph-headings">
				<h2 class="graph-h2">{{userInfo.createTime}}健康服务报表</h2>
				<h3 class="graph-h3">Stage physiological behavior report</h3>
			</div>
			<div class="user-info-panel">
				<div class="user-info">
					<span class="user-info-label">姓名</span>
					<span class="user-info-text">{{userInfo.name}}</span>
				</div>
				<div class="user-info">
					<span class="user-info-label">性别</span>
					<span class="user-info-text">{{userInfo.sex}}</span>
				</div>
				<div class="user-info">
					<span class="user-info-label">年龄</span>
					<span class="user-info-text">{{userInfo.age}}</span>
				</div>
				<div class="user-info">
					<span class="user-info-label">门店</span>
					<span class="user-info-text">{{userInfo.branch}}</span>
				</div>
			</div>
			<table class="main-table">
				<tr>
					<th style="color: #ff8d01;">总段位积分</th>
					<td style="color: #ff8d01;font-size: 24px;">{{data.jfsj.zdwjf}}</td>
					<th style="color: #01a5ff;">月段位积分</th>
					<td style="color: #01a5ff;font-size: 24px;">{{data.jfsj.ydwjf}}</td>
				</tr>
			</table>
			<div class="table-panel">
				<p class="table-title" style="background-color: #01a5ff;">检测</p>
				<table class="second-table" style="table-layout: auto;">
					<tr>
						<th colspan="2">检测项目</th>
						<th colspan="2">次数</th>
					</tr>
					<tr v-for="i in 4" :key="i">
						<td style="width: 35%;">{{data.jcsj.fltj[i - 1].picture__name}}</td>
						<td>{{data.jcsj.fltj[i - 1].cs}}</td>
						<td style="width: 35%;">{{data.jcsj.fltj[i - 1 + 4].picture__name}}</td>
						<td>{{data.jcsj.fltj[i - 1 + 4].cs}}</td>
					</tr>
					<tr style="color: #fff;background-color: #35b4f0;">
						<th colspan="2">项目合计</th>
						<th colspan="2">合计次数</th>
					</tr>
					<tr>
						<th colspan="2">{{data.jcsj.xmhj}}</th>
						<th colspan="2">{{data.jcsj.hjcs}}</th>
					</tr>
				</table>
			</div>
			<div class="flex-table">
				<div class="table-panel">
					<p class="table-title" style="background-color: #d50468;">监测</p>
					<table class="second-table">
						<tr>
							<th style="width: 70%">监测项目</th>
							<th>次数</th>
						</tr>
						<tr v-for="(item, i) in data.jcsj2.fltj" :key="i">
							<td>{{item.name}}</td>
							<td>{{item.count}}</td>
						</tr>
						<tr style="color: #fff;background-color: #ff9885;">
							<th>项目合计</th>
							<th>合计次数</th>
						</tr>
						<tr>
							<th>{{data.jcsj2.xmhj}}</th>
							<th>{{data.jcsj2.hjcs}}</th>
						</tr>
					</table>
				</div>
				<div style="width:20px;height:20px;flex:1;">&nbsp;&nbsp;&nbsp;</div>
				<div class="table-panel">
					<p class="table-title" style="background-color: #d50468;">测评与训练</p>
					<table class="second-table">
						<tr>
							<th style="width: 70%">检测项目</th>
							<th>次数</th>
						</tr>
						<tr v-for="(item, i) in data.cpyxl.fltj" :key="i">
							<td>{{item.jcxm}}</td>
							<td>{{item.cs}}</td>
						</tr>
						<tr style="color: #fff;background-color: #ff9885;">
							<th>项目合计</th>
							<th>合计次数</th>
						</tr>
						<tr>
							<th>{{data.cpyxl.hjxm}}</th>
							<th>{{data.cpyxl.hjcs}}</th>
						</tr>
					</table>
				</div>
			</div>
			<div class="table-panel">
				<table class="second-table">
					<tr style="background-color:#efefef;">
						<th rowspan="2" style="width:80px;background-color:#28aa0d;color:#fff;font-size:22px;">促进</th>
						<th>促进项目</th>
						<td>门店活动</td>
						<td>电访</td>
						<td>家访</td>
					</tr>
					<tr>
						<th>促进次数</th>
						<th>{{data.cjsj.mdhd}}</th>
						<th>{{data.cjsj.df}}</th>
						<th>{{data.cjsj.jf}}</th>
					</tr>
				</table>
			</div>
		</div>
</template>

<script  type="text/ecmascript-6">
export default {
	props: {
		userInfo: {
			type: Object,
		},
		data: {
			type: Object,
		},
	},
};
</script>

<style lang="less" scoped>
.user-info-panel {
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
}
.user-info-text {
  padding: 5px 25px;
  border-bottom: #000 solid 1px;
  margin-left: 5px;
}
.main-table {
	width: 80%;
	text-align: center;
	table-layout: fixed;
	margin: 25px auto 0;
	font-size: 18px;
	font-weight: 700;
	th {
		padding: 5px;
		border: 1px #bbb solid;
		:nth-child(odd) {
			background-color: #efefef;
		}
	}
	td {
		padding: 5px;
		border: 1px #bbb solid;
	}
}
.text {
	color: #ff8d01;
	font-size: 18px;
	font-weight: 700;
	padding-bottom: 5px;
	border-bottom: 1px #bbb solid;
	margin: 15px 0;
}
.table-panel {
	margin-top: 15px;
	.table-title {
		padding: 6px;
		font-weight: 700;
		font-size: 16px;
		color: #fff;
		text-align: center;
	}
	.second-table {
		width: 100%;
		text-align: center;
		table-layout: fixed;
		td, th {
			padding: 5px;
			border: #bbb 1px solid;
			font-size: 16px;
		}
	}
}
.flex-table {
	display: flex;
}
</style>
