<template>
		<div class="overview-container">
			<div class="graph-header">
				<slot></slot>
			</div>
			<div class="graph-headings">
				<h2 class="graph-h2">{{userInfo.createTime}}适生行为报表</h2>
				<h3 class="graph-h3">Stage physiological behavior report</h3>
			</div>
			<div class="user-info-panel">
				<div class="user-info">
					<span class="user-info-label">姓名</span>
					<span class="user-info-text">{{userInfo.name}}</span>
				</div>
				<div class="user-info">
					<span class="user-info-label">性别</span>
					<span class="user-info-text">{{userInfo.sex}}</span>
				</div>
				<div class="user-info">
					<span class="user-info-label">年龄</span>
					<span class="user-info-text">{{userInfo.age}}</span>
				</div>
				<div class="user-info">
					<span class="user-info-label">门店</span>
					<span class="user-info-text">{{userInfo.branch}}</span>
				</div>
			</div>
			<div class="table-panel">
				<p class="table-title" style="background-color: #fdad4a;">近体生命物质</p>
				<table class="second-table">
					<tr>
						<td>近体物质</td>
						<td v-for="i in 5" :key="i">{{data.recovery.fjA.ysmc[i]}}</td>
					</tr>
					<tr>
						<td>使用方法</td>
						<td v-for="i in 5" :key="i">{{data.recovery.fjA.syfs[i]}}</td>
					</tr>
				</table>
			</div>
			<div class="table-panel">
				<p class="table-title" style="background-color: #ff9885;">复健</p>
				<table class="second-table">
					<tr>
						<td>复健名称</td>
						<td v-for="i in 5" :key="i">{{data.recovery.fjB.ysmc[i]}}</td>
					</tr>
					<tr>
						<td>使用方法</td>
						<td v-for="i in 5" :key="i">{{data.recovery.fjB.syfs[i]}}</td>
					</tr>
				</table>
			</div>
			<div class="graph-container graph-flex-box">
				<div class="graph-flex-panel">
					<div class="graph-panel" ref="graph1"></div>
					<div>
						<p class="graph-title">监测结果分析: </p>
						<p class="graph-content">{{this.data.threeCharts.srzl_bj.jgfx}}</p>
					</div>
				</div>
				<div class="graph-flex-panel">
					<div class="graph-panel" ref="graph2"></div>
					<div>
						<p class="graph-title">监测结果分析: </p>
						<p class="graph-content">{{this.data.threeCharts.yxnh_bj.jgfx}}</p>
					</div>
				</div>
				<div class="graph-flex-panel">
					<div class="graph-panel" ref="graph3"></div>
					<div>
						<p class="graph-title">监测结果分析: </p>
						<p class="graph-content">{{this.data.threeCharts.xlcs_bj.jgfx}}</p>
					</div>
				</div>
			</div>
		</div>
</template>

<script  type="text/ecmascript-6">
import echarts from 'echarts';

export default {
	props: {
		userInfo: {
			type: Object,
		},
		data: {
			type: Object,
		},
	},
	data() {
		return {};
	},
	mounted() {
		this.buildChart(
			this.$refs.graph1,
			'摄入总量',
			this.data.threeCharts.srzl_bj.y_list,
			this.data.threeCharts.srzl_bj.xwmb,
		);
		this.buildChart(
			this.$refs.graph2,
			'有效能耗',
			this.data.threeCharts.yxnh_bj.y_list,
			this.data.threeCharts.yxnh_bj.xwmb,
		);
		this.buildChart(this.$refs.graph3, '训练次数', this.data.threeCharts.xlcs_bj.y_list);
	},
	methods: {
		buildChart(ele, title, yData, target) {
			const series = [
				{
					type: 'bar',
					data: yData,
					color: '#757575',
					markLine: {
						symbol: 'none',
						data: [
							{
								type: 'average',
								name: '期间平均值',
								label: {
									normal: {
										position: 'insideEndTop',
										formatter: '{b}',
									},
								},
								lineStyle: {
									color: '#000',
									type: 'solid',
								},
							},
						],
					},
				},
			];
			if (target) {
				const data = new Array(3).fill(target);
				series.push({
					type: 'line',
					symbol: 'none',
					data,
					lineStyle: {
						width: 0,
					},
					markLine: {
						symbol: 'none',
						data: [
							{
								type: 'average',
								name: '行为目标',
								label: {
									normal: {
										position: 'insideEndTop',
										formatter: '{b}',
									},
								},
								lineStyle: {
									color: '#000',
									type: 'solid',
								},
							},
						],
					},
				});
			}
			echarts.init(ele).setOption({
				title: {
					left: 'center',
					text: title,
				},
				grid: {
					left: '38',
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						crossStyle: {
							color: '#999',
						},
					},
				},
				xAxis: [
					{
						type: 'category',
						data: this.data.threeCharts.x_list,
						axisPointer: {
							type: 'shadow',
						},
					},
				],
				yAxis: [{ type: 'value' }, { type: 'value' }],
				series,
			});
		},
	},
};
</script>

<style lang="less" scoped>
.user-info-panel {
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
}
.user-info-text {
  padding: 5px 25px;
  border-bottom: #000 solid 1px;
  margin-left: 5px;
}
.main-table {
	width: 100%;
	margin-top: 30px;
	text-align: center;
	table-layout: fixed;
	tr:nth-child(odd) {
		background-color: #efefef;
	}
	th {
		background-color: #4472c4;
		font-weight: 700;
		color: #fff;
		padding: 8px 4px;
		border: 1px #000 solid;
	}
	td {
		padding: 10px 5px;
		border: 1px #000 solid;
	}
}
.graph-container {
	margin-top: 30px;
	width: 100%;
	height: 400px;
	padding-top: 30px;
	border-top: #bbb solid 1px;
}
.graph-flex-box {
	display: flex;
	justify-content: space-between;
	height: auto;
	.graph-flex-panel {
		width: 32.5%;
	}
}
.table-panel {
	margin-top: 30px;
	.table-title {
		padding: 15px;
		font-weight: 700;
		font-size: 16px;
		color: #fff;
		text-align: center;
	}
	.second-table {
		width: 100%;
		text-align: center;
		table-layout: fixed;
		td {
			height: 50px;
			border: #eee 2px solid;
			font-size: 16px;
		}
	}
}
.graph-panel {
	height: 400px;
}
.graph-title,
.graph-content {
	margin-bottom: 5px;
	font-weight: 700;
}
</style>
